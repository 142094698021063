@import '../variables.module.scss';

.Main {
    width: 100vw;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 40px;
    position: relative;
    margin-top: 80px;

    @media screen and (max-width: $breakpoint-tablet)   {
        padding: 10px 20px;
        margin-top: 60px;
    }

    .controller {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: calc(50% - calc($gap / 2)) calc(50% - calc($gap / 2));
        grid-template-rows: minmax(30vh, 100%) auto auto auto auto;
        column-gap: $gap;
        row-gap: $gap;

        @media screen and (max-width: $breakpoint-tablet)   {
            grid-template-rows: auto;
            grid-template-columns: 100%;
            grid-template-areas: "row-1" "row-2" "row-3" "row-4" "row-4" "row-5" "row-6";
        }

        @media screen and (max-height: $breakpoint-tablet) and (orientation: landscape) {
            grid-template-rows: auto;
            grid-template-areas: "row-1" "row-2" "row-3" "row-4" "row-4" "row-5" "row-6";
        }

        &.canStarted {
            @media screen and (max-width: $breakpoint-tablet) {
                grid-template-areas: "row-1" "row-3" "row-4" "row-4" "row-5" "row-6";
            }
        }

        &>* {
            grid-column: 1 / 3;
            
            @media screen and (max-width: $breakpoint-tablet) {
                grid-column: auto;
            }
        }

        .list {
            @media screen and (max-width: $breakpoint-tablet) {
                height: 200px;
                grid-area: row-5
            }
        }

        .details {
            width: 100%;
            background: $deepGrey;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            grid-column: auto;

            h2 {
                margin-top: 0;
            }

            @media screen and (max-width: $breakpoint-tablet) {
                margin-left: 0;
                margin-top: 0;
                grid-area: row-3
            }

            .detail {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;

                &.battery {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 6em;

                    &>span {
                        width: 1em;
                        height: 1em;
                        transform: rotate(90deg);
                    }
                }

                span:first-child {
                    font-weight: bold;
                }

                @media screen and (max-width: $breakpoint-tablet) {
                    flex-direction: column;
                }
            }

            &.detailsFull {
                width: 100%;
                height: 100%;
            }
        }

        .detailsHalf {
            width: calc(50% - #{$gap});

            @media screen and (max-width: $breakpoint-tablet) {
                width: 100%;
                
                &:last-child {
                    display: none;
                }
            }
        }


        .detailsContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &.detailsContainerCharging {
                flex-direction: row;
                background: $deepGrey;
                border-radius: 5px;
                height: auto;

                @media screen and (max-width: $breakpoint-tablet) {
                    width: 100%;
                    flex-direction: column;
                    height: auto;
                    grid-area: row-2
                }
            }

            @media screen and (max-width: $breakpoint-tablet) {
                width: 100%;
                flex-direction: column;
                height: auto;
            }
        }
    }
}