@import '../variables.module.scss';
@import './Main.module.scss';

.Main {
    &.Shared {
        justify-content: flex-start;
        align-items: center;
    }

    .error {
        padding-top: 10vh;
        font-size: 2em;
    }
}