@import './Charger.module.scss';

.detailsContainer {
  &.detailsContainerCharging {
    flex-direction: row;
    flex-wrap: wrap;
    background: $deepGrey;
    border-radius: 5px;
    height: auto;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100% !important;
      flex-direction: column;
      height: auto;
      grid-area: row-2;
    }

    @media screen and (max-height: $breakpoint-tablet) and (orientation: landscape) {
      width: 100% !important;
      height: auto;
      grid-area: row-2;
      grid-column: 1/3;
    }

    .detailsHalf {
      .detailsFull {
        padding: 0;
        justify-content: center;

        .detail {
          & > span:first-child {
            margin-right: 10px;
          }
        }
      }
    }

    &:not(.detailsContainerHistory) {
      .details {
        flex-direction: column;

        @media screen and (max-width: $breakpoint-tablet) {
          flex-direction: row;
          justify-content: space-between;
        }

        .detail {
          width: 100%;

          @media screen and (max-width: $breakpoint-tablet) {
            width: auto;
          }
        }
      }
    }

    .details {
      .detail {
        width: auto;
        height: auto;
        cursor: auto;
        overflow: visible;
      }

      &.chart {
        @media screen and (max-width: $breakpoint-tablet) {
          padding-top: 0;
        }

        .detail {
          width: 100%;
        }
      }

      .battery,
      .car {
        text-align: center;
        max-height: 150px;
        margin: 0;
      }

      .smallCar {
        width: 50px;
        margin: 0 10px;
      }

      .bigCar {
        width: 10em;
      }

      &.batteryAndCar {
        justify-content: center;

        .details {
          justify-content: center;
        }

        @media screen and (max-width: $breakpoint-tablet) {
          flex-direction: row;
          padding: 0 20px;

          .battery,
          .car {
            width: 50%;
            max-height: 100px;
          }
        }
      }
    }
  }

  &.detailsContainerHistory {
    width: 100%;
    border-radius: 0 5px 5px 0;

    .detailsHalf {
      width: 100%;

      @media screen and (max-width: $breakpoint-tablet) {
        &:last-child {
          display: flex;
        }
      }

      .detailsFull {
        flex-direction: row;
        justify-content: flex-start;

        @media screen and (max-width: $breakpoint-tablet) {
          padding: 0;
          flex-wrap: wrap;
        }

        .detail {
          // margin-bottom: 0;
          position: relative;
          min-width: 10%;

          & > span:last-child:not(:first-child),
          & > time {
            margin-left: 0;
            margin-right: auto;
            text-align: left;
          }

          &.clickable {
            cursor: pointer;
          }

          @media screen and (max-width: $breakpoint-tablet) {
            font-size: 0.7em;
          }

          > span:first-child {
          }
          > span:nth-child(2) {
            display: none;
          }

          &:hover {
            > span:nth-child(2) {
              position: absolute;
              display: block;
              color: $white;
              padding: 10px;
              font-size: 0.6em;
              width: 200%;
              white-space: nowrap;
              top: -2em;
              left: -0.6em;
            }
          }

          &.detailOnlyIcon {
            min-width: auto;

            > span:first-child {
              margin-right: 0;
            }

            & + .detail {
              margin: 0 0 12px;

              @media screen and (max-width: $breakpoint-tablet) {
                margin: 0 5px 12px;
              }
            }
          }
        }
      }
    }
  }
}
