@import '../variables.module.scss';

.Main {
    width: 100vw;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    margin-top: 100px;

    @media screen and (max-width: $breakpoint-tablet)   {
        margin-top: 60px;
    }

    .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        
        .charger {
            width: 30%;
            height: 30vh;
            min-height: 200px;
            background: $transparentWhite;
            color: $white;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            overflow: hidden;

            @media screen and (max-width: $breakpoint-tablet) {
                width: 100%;
            }

            &.used {
                background: $red;
            }

            .title {
                height: 40px;
                padding: 10px;
                display: flex;
                flex-direction: revert;
                justify-content: space-between;
                align-items: center;
                
                .iconPack {
                    display: flex;
                    margin-right: 0;
                }

                .icon {
                    margin-right: 0;
                    margin-left: auto;
                    width: 30px;

                    &>span:last-child {
                        display: none;
                    }
                }
            }
    
            .map {
                height: calc(100% - 40px);
            }
        }
    }
}