@import '../variables.module.scss';

.backdropCheck {
    background-color: $darkGreyTransparent !important;

    @supports ((-webkit-backdrop-filter: blur(40px)) or (backdrop-filter: blur(40px))) {
        background-color: transparent !important;
        -webkit-backdrop-filter: blur(40px);
        backdrop-filter: blur(40px);
    }
}