@import '../variables.module.scss';

.Header {
  background-color: #{$darkBlue + 'FF'};
  width: 100vw;
  height: 80px;
  padding: 20px;
  text-align: center;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  transition: background-color .2s, backdrop-filter .2s;

  &.fixed {
    @supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
      background-color: #{$darkBlue + '66'};
      backdrop-filter: blur(50px);
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    font-size: 1em;
    height: 60px;
  }

  .globalStatus {
    background-color: transparent;
    width: 15px;
    height: calc(100% + 40px);
    margin: -20px 10px -20px -20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &>span {
      font-size: 12px;
      transform: rotate(-90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      color: $deepGrey;
    }

    &.Normal {
      background-color: $green;
    }

    &.High {
      background-color: $orange;

      &>span {
        color: $white;
      }
    }

    &.VeryHigh {
      background-color: $red;

      &>span {
        color: $white;
      }
    }
  }

  .back {
    color: $white;
  }

  .langs {
    margin-right: 0;
    margin-left: auto;
    
    .flagButton {
      width: 30px;
      height: 30px;
      padding: 0;
      border: none;
      border-radius: 50%;
      overflow: hidden;
      margin: 5px;
      outline: none;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
        cursor: auto;
        opacity: .5;
      }

      .flag {
        width: 30px;
        height: 30px;
      }
    }
  }

  img {
    max-height: 1em;
    margin-right: 1em;
  }
}

@mixin keyframes($name, $color) {
  @keyframes #{$name} {
    0% {
      transform: scale(1.2);
      box-shadow: 0 0 0 0 #{$color + "AA"};
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px #{$color + "00"};
    }
    
    100% {
      transform: scale(1.2);
      box-shadow: 0 0 0 0 #{$color + "00"};
    }
  }
}

.debugDiv,
.temperature {
  background: $red;
  color: $white;
  width: 100%;
  height: 30px;
  padding: 5px;
  font-size: 0.8em;
  text-align: center;
  position: fixed;
  z-index: 1000;
  white-space: nowrap;
  overflow-x: auto;

  &.temperature {
    font-size: 1.6em;
    height: 50px;

    @include keyframes('pulse-red', $red);
    @include keyframes('pulse-orange', $orange);
    @include keyframes('pulse-green', $green);
    animation: pulse-red 2s infinite;

    &.Normal {
      background-color: $green;
      color: $darkGrey;
      // animation: pulse-green 2s infinite;
      animation: none;
      display: none;
    }

    &.High {
      background-color: $orange;
      color: $white;
      animation: none;
      // animation: pulse-orange 2s infinite;
    }

    &.VeryHigh {
      background-color: $red;
      color: $white;
      animation: pulse-red 2s infinite;
    }
  }

  .debugItem {
    margin-right: 5px;
    .debugItemLabel {
      font-weight: bold;
      color: $white;
    }
  }
}



:global {
  .debugDiv {
    +.Header {
      margin-top: 30px;
      
      +.Main,
      +.Form .Main {
        margin-top: 110px;
    
        @media screen and (max-width: $breakpoint-tablet)   {
            margin-top: 90px;
        }
      }
    }
  }

  .temperature {
    +.Header {
      margin-top: 50px;
      
      +.Main,
      +.Form .Main {
        margin-top: 130px;
    
        @media screen and (max-width: $breakpoint-tablet)   {
            margin-top: 110px;
        }
      }
    }
  }

  .temperature {
    + .debugDiv {
      top: 50px;

      +.Header {
        margin-top: 80px;
        
        +.Main,
        +.Form .Main {
          margin-top: 160px;
      
          @media screen and (max-width: $breakpoint-tablet)   {
              margin-top: 140px;
          }
        }
      }
    }
  }
}