@import '../variables.module.scss';

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 10vh;
  padding: 40px;

  a {
    color: $green;

    &:hover {
      color: $greenHover;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
    margin-top: 0;
  }

  .container {
    width: 25% !important;
    min-width: 500px;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100% !important;
      flex-direction: column !important;
      min-width: auto;
    }
  }

  .title {
    display: flex;
    flex-direction: revert;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 10px;
  }
  .iconLink {
    // white-space: nowrap;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .icon {
    margin-right: 10px;
    border-radius: 13px;
    padding: 0;
    overflow: hidden;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  .iconList {
    display: flex;
    margin-left: 20px;
    width: 60px;

    .icon {
    }
  }

  .label {
    @media screen and (max-width: $breakpoint-tablet) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }

  .element {
    @media screen and (max-width: $breakpoint-tablet) {
      width: 100% !important;
    }
  }

  .submitContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100% !important;
    }

    .submitButton {
      width: auto;

      @media screen and (max-width: $breakpoint-tablet) {
        font-size: 1.2em;
        width: 100% !important;
      }
    }
  }
}
