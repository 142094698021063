@import '../variables.module.scss';

.icon {
    // color: $white;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: currentColor;

    svg {
        height: 1em;
        width: 1em;
    }

    &.inRowIcon {
        margin-right: 3px;
    }

    &.superIcon {
        font-size: .5em;

        svg {
            stroke: $deepGrey;
            stroke-width: 10em;
            paint-order: stroke;
        }
    }
}

.iconGroup {
    position: relative;
    padding-right: 5px;
    display: flex;

    .icon {
        padding-right: 0;
        
        @for $i from 2 through 4 {
            &:not(.inRowIcon):nth-child(#{$i}) {
                position: absolute;
                // top: 5px;
                top: 0;
                left: 0;

                &.superIcon {
                    left: #{($i * 5)}px;
                }
            }
          }
    }
}