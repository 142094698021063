@import '../variables.module.scss';

.car {
  width: 2em;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  margin: auto;
}

.detail {
  font-size: 0.3em;
}
