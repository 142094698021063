@import '../variables.module.scss';

.background {
  background: transparent;
  background-color: transparent !important;
  backdrop-filter: blur(40px);
  color: $white !important;

  @supports not (backdrop-filter: blur(40px)) {
    background:$darkGrey;
  }
  
  .dialog {
    position: relative;

    .title {
      flex: 1;
    }
  }
}