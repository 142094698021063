@import '../variables.module.scss';
@import './Main.module.scss';

.Main {
    &.Admin {
        // background-color: $darkRed;
    }

    .controller { 
        display: flex;
        justify-content: center;
        align-items: center;

        .loginContainer {
            width: 25% !important;
            min-width: 500px;

            @media screen and (max-width: $breakpoint-tablet) {
                width: 100% !important;
                flex-direction: column !important;
                min-width: auto;
            }
        }

        .label {
            @media screen and (max-width: $breakpoint-tablet) {
                width: 100% !important;
                margin-bottom: 10px;
            }
        }

        .element {
            @media screen and (max-width: $breakpoint-tablet) {
                width: 100% !important;
            }
        }

        .submitContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            
            @media screen and (max-width: $breakpoint-tablet) {
                width: 100% !important;
            }

            .submitButton {
                width: auto;

                @media screen and (max-width: $breakpoint-tablet) {
                    font-size: 1.2em;
                    width: 100% !important;
                }
            }
        }
    }

    .mainButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $gap;

        @media screen and (max-width: $breakpoint-tablet) {
            position: relative;
        }

        button {
            font-size: 1em;
            margin-right: $gap;
            margin-left: 0;
            background-color: transparent;
            transition: color .2s, background-color .2s;
            padding: 0 10px 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 20px;
            min-height: 40px;

            &>span[class*="Icon"] {
                transition: color .2s, background-color .2s;
                background: $red;
                padding: 10px;
                height: 40px;
                width: 40px;
                display: inline-block;
                border: none;
                box-sizing: border-box;
                border-radius: 50%;
                margin-right: 5px;

                @media screen and (max-width: $breakpoint-tablet) {
                    margin-right: 0;
                }
            }

            &>.buttonLabel {
                @media screen and (max-width: $breakpoint-tablet) {
                    display: none;
                }
            }

            &:hover,
            &:hover>span[class*="Icon"] {
                background: $redHover;
            }
            
            @media screen and (max-width: $breakpoint-tablet) {
                margin: 0 10px calc($gap / 2) 0;
                width: 100%;
                font-size: 1em;
                padding: 0;
            }
        }

        .leftButtonContainer,
        .rightButtonContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            @media screen and (max-width: $breakpoint-tablet) {
                // flex-direction: column;
            }

            &.rightButtonContainer {
                justify-content: flex-end;

                button {
                    margin-left: $gap;
                    margin-right: 0;
                   
                    @media screen and (max-width: $breakpoint-tablet) {
                        margin: 0 0 calc($gap / 2) 10px;
                    }
                }
            }
        }

        .saveButton  {
            &>span[class*="Icon"] {
                background-color: $green;
                color: $darkGrey;
            }

            &:hover,
            &:hover>span[class*="Icon"] {
                color: $darkGrey;
                background-color: $greenHover;
            }
        }

        .shutDownButton {
            &>span[class*="Icon"] {
                background-color: $black;
            }

            &:hover,
            &:hover>span[class*="Icon"] {
                background-color: $blackHover;
            }
        }

        .logoutButton  {

            &>span[class*="Icon"] {
                background-color: $darkBlue;
            }


            &:hover,
            &:hover>span[class*="Icon"] {
                background-color: $darkBlueHover;
            }
        }
    }
}