@import '../variables.module.scss';

@keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}

.batteryIcon {
    width: 100%;
    height: 100%;
    position: relative;


    &>span {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6em;

        >svg {
            width: 2em;
            height: 2em;
            transform: rotate(90deg);
        }

        &.pulse {
            position: absolute;
            top: 0;
            animation: flash linear 2s infinite;

            &.halved {
                animation: none;
                opacity: .5;
            }
        }

        &.energyInfo {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 1.3em;
            width: 1.5em;
            height: 1.5em;
            text-align: center;
            transform: translate(-50%, -50%);
            color: $deepGrey;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;        
        }
    }
}