@mixin buttons($size, $length) {
  $fontSize: 1.8;
  $mobileFontSize: 1.2;
  @for $i from 1 through $length {
    &.buttons-#{$i} {
      width: calc(#{$size} / #{$i});

      font-size: #{$fontSize - ($i - 1) * 0.2}em;
        
      @media screen and (max-width: $breakpoint-tablet) {
        font-size: #{$mobileFontSize - ($i - 1) * 0.1}em;
      }
    }
  }
}

@mixin nths($size) {
  @for $i from 1 through $size {
    &:nth-child(#{$i}) {
      @content; 
    }
  }
}