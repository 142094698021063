@import '../mixins.scss';
@import '../variables.module.scss';

.buttonWrapper {
  position: relative;

  // .flyouts {
  //   position: relative;

  //   &>div {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //   }
  // }
}

.paper {
  width: calc(100% - 80px);
  background-color: $green !important;
  overflow: visible !important;

  .menuItem {
    font-weight: 800;
    overflow: visible;
    flex-wrap: wrap;
  }
}

.paperRed {
  width: calc(100% - 80px);
  background-color: $red !important;
  color: $white;
  overflow: visible !important;

  .menuItem {
    color: $white;
    font-weight: 800;
    overflow: visible;
    flex-wrap: wrap;
  }
}

.socSliderContainer {
  width: 50% !important;
  margin-right: 0;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 100% !important;
  }

  .socLabel {
    margin-right: 20px;
  }

  .socSlider {
  }
}

.carListItem {
  width: 50px;
  margin: -5px 10px 0 0;
}

.datepicker {
  display: none !important;
}

.touchfix {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 100%;
    grid-area: row-1;
  }

  @media screen and (max-height: $breakpoint-tablet) and (orientation: landscape) {
    grid-area: row-1;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    // height: 100%;
    width: 100%;
    font-size: 2em;
    border-radius: 0;

    @include buttons(100%, 10);

    &.buttons-1 {
      flex-direction: row;
    }

    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 1.2em;
    }

    &.start {
      background: $green;
      color: $darkGrey;
      width: 100%;

      @include buttons(100%, 10);

      @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;

        @include buttons(100%, 10);
      }

      &:hover {
        background: $greenHover;
      }
    }

    &.schedulable,
    &.hasCar {
      width: 90%;
      height: 100%;

      @include buttons(90%, 10);

      @media screen and (max-width: $breakpoint-tablet) {
        width: 90%;

        @include buttons(90%, 10);
      }

      &.hasCar.schedulable {
        width: 80%;
        height: 100%;

        @include buttons(80%, 10);

        @media screen and (max-width: $breakpoint-tablet) {
          width: 80%;

          @include buttons(80%, 10);
        }
      }

      &.hasCar + .schedule {
        // border-radius: 0;
      }
    }

    &.car {
      flex-direction: column !important;
    }

    &.schedule,
    &.car {
      background: $green;
      color: $darkGrey;
      width: 10%;
      height: 100%;
      padding: 5px;
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;

      @media screen and (max-width: $breakpoint-tablet) {
        width: 10%;
        // padding: 10px 0;
      }

      .scheduleIcon,
      .carIcon {
        color: $darkGrey;
        font-size: 0.9em;
      }

      &.stop {
        .scheduleIcon,
        .carIcon {
          color: $white;
        }
      }

      &:hover {
        background: $greenHover;
      }
    }

    &.stop {
      background: $red;
      color: $white;
      flex-direction: row;

      &:hover {
        background: $redHover;
      }
    }

    &.forcing {
      background: linear-gradient(
        to right,
        $green 100%,
        $green 120%,
        $red 180%,
        $red 200%
      );

      &.f3 {
        background: linear-gradient(
          to right,
          $green 75%,
          $green 95%,
          $red 155%,
          $red 175%
        );
      }
      &.f4 {
        background: linear-gradient(
          to right,
          $green 50%,
          $green 70%,
          $red 130%,
          $red 150%
        );
      }
      &.f5 {
        background: linear-gradient(
          to right,
          $green 25%,
          $green 45%,
          $red 105%,
          $red 125%
        );
      }
      &.f6 {
        background: linear-gradient(
          to right,
          $green 0%,
          $green 20%,
          $red 80%,
          $red 100%
        );
      }
      &.f7 {
        background: linear-gradient(
          to right,
          $green -25%,
          $green -5%,
          $red 55%,
          $red 75%
        );
      }
      &.f8 {
        background: linear-gradient(
          to right,
          $green -50%,
          $green -30%,
          $red 30%,
          $red 50%
        );
      }
      &.f9 {
        background: linear-gradient(
          to right,
          $green -75%,
          $green -55%,
          $red 5%,
          $red 25%
        );
      }
      &.f10 {
        background: linear-gradient(
          to right,
          $green -100%,
          $green -80%,
          $red -20%,
          $red 0%
        );
      }
    }

    .copy {
      font-style: italic;
      font-weight: 700;
      font-size: 0.3em;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;

      .icon {
        color: $darkGrey;
      }
    }

    .lottieIcon {
      height: 40px;
      width: 40px;
      font-size: 2em;
      padding-bottom: 0;
      margin-right: 20px;
      display: flex;

      @media screen and (max-width: $breakpoint-tablet) {
        height: 24px;
        width: 24px;
      }

      svg {
        height: 40px;
        width: 40px;

        @media screen and (max-width: $breakpoint-tablet) {
          height: 24px;
          width: 24px;
        }
      }
    }

    .loader {
      .loaderIcon {
        height: 40px;
        width: 40px;
        font-size: 2em;
        padding-bottom: 0;
        margin-right: 20px;
        display: flex;

        @media screen and (max-width: $breakpoint-tablet) {
          height: 24px;
          width: 24px;
        }

        svg {
          height: 40px;
          width: 40px;

          @media screen and (max-width: $breakpoint-tablet) {
            height: 24px;
            width: 24px;
          }
        }
      }

      .startLoaderIcon {
        color: $darkGrey;
      }
    }
  }
}
