@import '../variables.module.scss';

.error {
  padding: 10px;
  color: red;
  background: white;
  width: 100%;
  border-radius: 5px;
  font-size: 0.6em;
  text-align: center;
  width: 100%;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}