@import '../../variables.module.scss';

.container {
    width: 100%;

    h2 {
        text-align: center;
    }

    .fields { 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &>.errorMSG {
            flex-wrap: wrap;
            background: $red;
            padding: 5px;
            margin: -5px -5px 20px;
            border-radius: 5px;
            width: 50%;
            color: $white;
            text-align: center;
            font-weight: 700;
        }
        
        .field {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap; 
            justify-content: center;
            align-items: center;
            width: 50%;
            margin-bottom: 20px;

            &.error {
                flex-wrap: wrap;
                background: $red;
                padding: 5px;
                margin: -5px -5px 20px;
                border-radius: 5px;

                .errorMSG {
                    width: 100%;
                    color: $white;
                    text-align: center;
                    font-weight: 700;
                }
            }

            &>* {
                width: 100%;
            }

            &>.label {
                width: 50%;
                font-size: 1em;

                &+*{
                    width: 50%;
                }
            }

            input, select, textarea{
                &.element,
                &.element:-webkit-autofill,
                &.element:-webkit-autofill,
                &.element:-webkit-autofill:hover, 
                &.element:-webkit-autofill:focus, 
                &.element:-webkit-autofill:active,
                &.element:-internal-autofill-selected {
                    padding: 20px 40px;
                    border-radius: 5px;
                    background: $deepGrey;
                    color: $white;
                    border: 1px solid #00000055;
                    transition: border .4s;
                    font-size: 1em;;

                    &:focus {
                        outline: none;
                        border: 1px solid #000000dd;
                    }
                }
            }
        }

        .RadioButtons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .Select {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position: static !important;
            min-width: 100px;
            color: $white;

            &>.SelectRoot {
                margin: 0;
            }
        }

        .submitContainer {
            width: 50%;

            .submitButton {
                padding: 10px 20px;
                background-color: $darkBlue;
                border-radius: 5px;
                border: none;
                color: $white;
                cursor: pointer;
                outline: none;
                font-size: 2em;
                width: 100%;
            
                &:hover {
                    background-color: $darkBlueHover;
                }
            
                &:disabled,
                &.disabled {
                cursor: auto;
                opacity: .5;
                }
            }
        }
    }
}