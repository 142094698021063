@import '../variables.module.scss';


.formControl {
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  
  &.formControlOpened {
    pointer-events: all;
    opacity: 1;
  }

  .label {
    color: $white;
    display: none;
  }

  .shownLabel {
    display: block;
  }

  .select {
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
  }
}