@import '../variables.module.scss';

.Button {
  padding: 10px 20px;
  background-color: $red;
  border-radius: 5px;
  border: none;
  color: $white;
  cursor: pointer;
  outline: none;
  transition: background-color .2s;

  &:hover {
    background-color: $redHover;
  }

  &:disabled,
  &.disabled,
  &:disabled ~ &,
  &.disabled ~ & {
    pointer-events: none;
    cursor: auto;
    opacity: .5;
  }
}