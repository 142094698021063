@import '../variables.module.scss';

.History {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        padding-top: 0;
        grid-area: row-6;
    }

    .addons {
        width: 100%;
        display: flex;
        flex-direction: column;

        .addon {
            width: 100%;
            transition: height .3s ease-in-out, opacity .3s ease-in-out;
            opacity: 0;
            height: 0;

            &.active {
                opacity: 1;
                height: fit-content;
            }
        }
    }

    .addonTabs {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 20px;

        h2 {
            opacity: 1;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 20px;
            font-size: 1.5em;

            &:not(.active) {
                cursor: pointer;
                opacity: 0.7;
                font-size: 1.2em;
            }

            @media screen and (max-width: $breakpoint-tablet) {
                margin-top: 0;
            }

            @media screen and (max-height: $breakpoint-tablet) and (orientation: landscape) {
                margin-top: 0;
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
        
        .charging {
            width: 100%;
            background: $transparentWhite;
            color: $white;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
            animation: fadein 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
            transition: background-color .2s, border-left-width .2s;
            overflow: hidden;
            // height: 60px;
            opacity: 0;
            border-left: 15px solid transparent;

            @media screen and (max-width: $breakpoint-tablet) {
                width: 100%;
            }



            &>div {
                width: 100% !important;
            }

            .title {
                height: 40px;
                padding: 10px;
            }
        }
    }
}