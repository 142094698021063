@import './variables.module.scss';

body {
  background-color: $backgroundGrey;
}

.App {
  background-color: $backgroundGrey;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: left;
  font-size: 20px;
  color: $white;
}

* {
  box-sizing: border-box;
}