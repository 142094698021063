@import '../mixins.scss';
@import '../variables.module.scss';
@import './Icon.module.scss';

.maps {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    grid-column: auto !important;
}

.video {
    width: 100%;
    height: 50%;
    border-radius: 5px 5px 0 0;
    display: none;

    &.videoLoaded {
        display: block;

        +div {
            height: 50%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.details {
    width: 100%;
    background: $deepGrey;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    padding: 20px;
    flex-direction: row;
    justify-content: center;
    // align-items: flex-start;

    &:not(.isAdmin) {
        grid-column: auto !important;
    }

    &.detailsMapAndVideo {
        padding: 0;

        @media screen and (max-width: $breakpoint-tablet) {
            height: 400px;
            grid-area: row-4
        }
    }

    h2 {
        margin-top: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;

        .titleIcon {
            margin-left: 5px;
        }

        @media screen and (max-width: $breakpoint-tablet) {
        }
    }

    @media screen and (max-width: $breakpoint-tablet) {
        margin-left: 0;
        margin-top: 0;
        grid-area: row-3;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;
    }

    @media screen and (max-height: $breakpoint-tablet) and (orientation: landscape) {
      grid-area: auto;
    } 

    &:hover { 
        .detail {
            opacity: .7;
        }
    }

    .detail {
        margin: 0 5px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        width: 1.2em;
        height: 1.2em;
        overflow: hidden;
        transition: width .1s, height .1s, opacity .3s, margin-bottom .1s, font-size .3s;
        order: 2;
        
        // @for $i from 1 through 100 {
        //     &:nth-child(#{$i}) {
        //         transition-delay: #{($i - 5) * .11}s;
        //     }
        // }
    
        @include nths(4) {
            order: 0;
        }
        
        &.more {
            width: 100% !important;
            cursor: pointer !important;
            height: 2em;
            opacity: 1;
            
            @media screen and (max-width: $breakpoint-tablet) {
                order: 1;
            }

            &.hidden {
                height: 0; 
                opacity: 0;
                margin-bottom: 0;
                pointer-events: none;
            }
        }

        &.iconsContainer {    
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
                    
            .label {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: auto;
                position: relative;
                margin-bottom: 10px;

                .icon {
                    color: $white;
                    // padding-right: 0;

                    &.supIcon {
                        width: 1.4em;
                        height: 1.4em;
                        align-self: flex-start;
                    }

                    svg {
                        width: 2em;
                        height: 2em;
                    }
                }
            }
        }

        &.iconsContainer:not(.more) {  
            overflow: visible;

            .label {                
                &>span:nth-child(2) {
                    display: none;
                    position: absolute;
                    color: $white;
                    padding: 10px;
                    font-size: 0.6em;
                    width: 200%;
                    white-space: nowrap;
                    top: -1.2em;
                    left: 5.4em;
                    text-align: left;
                }

                &:hover {
                    &>span:nth-child(2) {
                        display: block;
                    }
                }
            }
        }

        .green {
            color: $green;
        }

        .red {
            color: $red;
        }

        .yellowRed {
            color: $orange;

            svg {
                fill: url('#boostGradient');
            }
        }

        .boosted {
            color: $green;
            font-weight: 800;
        }
        
        @media screen and (max-width: $breakpoint-tablet) {
            margin-bottom: 10px;
            font-size: 0.7em;
            // overflow: visible;
        }

        &>span:nth-child(2) {
            font-weight: bold;

            @media screen and (max-width: $breakpoint-tablet) {
                display: none;
            }
        }

        &:not(.status):not(.weatherContainer):not(.groupContainer):not(.more):hover {
            opacity: 1;
            font-size: 1.1em;

            @media screen and (max-width: $breakpoint-tablet) {
                overflow: visible;
                font-size: 0.8em;
            }

            &>span:nth-child(2) {
                @media screen and (max-width: $breakpoint-tablet) {
                    position: absolute;
                    display: block;
                    color: $white;
                    padding: 10px;
                    font-size: 0.6em;
                    width: 200%;
                    white-space: nowrap;
                    top: -2em;
                    left: -0.6em;
                }
            }
        }

        &>span:last-child:not(:first-child),
        &>time {
            margin-left: auto;
            text-align: right;
        }

        &>:global(.MuiFormControl-root),
        &>input,
        &>div {
            background-color: transparent;
            margin-left: auto;
            text-align: right;
            font-size: 1em;
            border: none;
            color: $white;

            &>:global(.MuiInput-root) {
                color: $white;
            }
        }

        &>input {
            background-color: $transparentWhite;
            padding: 5px;
            max-width: 200px;
            width: fit-content;

            &.arrayInput {
                max-width: 100px;
                margin-right: 0;
                margin-left: auto;
            }

            &+input,
            &+:global(.MuiFormControl-root) {
                margin-left: 0;
                &+:global(.MuiFormControl-root) {
                    margin-left: 0;
                }
            }
        }

        &>:global(.MuiFormControl-root) {
            margin-left: auto;
            background-color: $transparentWhite;
            padding-bottom: 1px;
            max-width: 200px;
            width: fit-content;
        }

        &.groupContainer {
            width: 100%;
            height: auto;
            opacity: 1 !important;

            &>.detail {
                margin: 0;
                opacity: 1 !important;
            }

            @media screen and (max-width: $breakpoint-tablet) {
                width: 100%;
            }
        }

        &.shown {
            width: 100%;
            height: auto;
            cursor: auto;

            &:not(.groupContainer) {
                @media screen and (max-width: $breakpoint-tablet) {
                    width: auto;
                }
            }

            // &>span:nth-child(2) {
            //     display: inline;

            //     @media screen and (max-width: $breakpoint-tablet) {
            //         display: none;
            //     }
            // }
        }

        &.status, 
        &.weatherContainer {
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            line-height: normal;
            font-size: 2em;
            margin-left: 0;
            height: auto;

            &.detail {
                width: 100%;
            }
            &.detailHalf {
                width: 50%;

                @media screen and (max-width: $breakpoint-tablet) {
                    width: 100%;
                }
            }

            &.weatherContainer {
                transition: opacity .5s ease-in-out, transform .5s ease-in-out;
                opacity: 1;
                transform: translateY(0);
                pointer-events: none;

                &.weatherNextContainer {
                    transition: none;
                    opacity: 0;
                    transform: translateY(100%);
                }

                &.weatherPrevContainer {
                    opacity: 0;
                    transform: translateY(-100%);
                }
            // .weather {
                // display: flex;
                // flex-direction: column;
                // justify-content: flex-end;
                // align-items: flex-start;
                // margin: 0;
                // position: relative;
                // font-size: 2em;

                // &>span:nth-child(2) {
                //     line-height: normal;
                //     font-size: 14px;
                // }

                &>span:last-child {
                    line-height: normal;
                    position: absolute;
                    font-size: 14px;
                    top: -10px;
                }

                svg {
                    width: 1.5em;
                    height: 1.5em;
                    margin-top: -10px;
                }

                // &.today {
                //     &>span:nth-child(2),
                //     &>span:last-child {
                //         font-size: 16px;
                //     }

                //     svg {
                //         width: 1em;
                //         height: 1em;
                //     }
                // }
            }

            &>span {
                justify-content: center;
                display: block;
                margin: 5px !important;
                height: 1em;
            }

            @media screen and (max-width: $breakpoint-tablet) {
                line-height: normal;
            }
        }
    }

    &.detailsFull {
        width: 100%;
        height: 100%;
    }

    &.chargerDetails {
        &:hover {
            &>.detail:not(.shown):not(.groupContainer) {
                opacity: 0.7;
            }
        }

        &>.detail:not(.shown):not(.groupContainer) {
            :global(.icon):not(.active) {
                filter: grayscale(1);
            }
        }

        &>.detail:not(.shown):not(.groupContainer):hover {
            opacity: 1;
            overflow: visible;
            font-size: 1.1em;

            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 0.8em;
            }

            &>span:nth-child(2),
            &>span:nth-child(3) {
                position: absolute;
                display: block;
                text-align: left;
                padding: 10px;
                font-size: 0.6em;
                width: 200%;
                white-space: nowrap;
                left: -0.6em;
                
                &:nth-child(2) {
                    top: -2em;
                    color: $white;
                }

                &:nth-child(3) {
                    bottom: -2em;
                }
            }
        }
    }

    .supIcon {
        width: 0.6em;
        height: 0.6em;
    }
}

.detailsHalf {
    width: calc(50% - #{$gap});

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
    }
}


.detailsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        flex-direction: column;
        height: auto;
    }
}