@import '../variables.module.scss';

.Map {
    height: 100%;
    width: 100%;
    position: relative;

    @media screen and (max-width: $breakpoint-tablet) {
        min-height: 160px;
    }

    .formControl { 
        position: absolute;
        transform: translate(0, 0) scale(1);
        bottom: 20px;
        left: 20px;
        z-index: 90;
        min-width: 120px;
        background-color: $lightGray;
        border-radius: 5px;

        &:hover { 
            background-color: $lightGrayHover;
        }

        .label {
            top: 50%;
            left: 10px;
            position: absolute;
            transform: translate(0, -50%) scale(1);
        }

        .select { 
            margin-top: 0;

            &:before,
            &:after { 
                display: none;
            }
        }
    }
}

.waze {
    color: $white !important;
    background-color: $waze !important;

    &:hover{ 
        background-color: $wazeHover !important;
    }
}

.here {
    color: $white !important;
    background-color: $here !important;

    &:hover{ 
        background-color: $hereHover !important;
    }
}

.google {
    color: $white !important;
    background-color: $google !important;

    &:hover{ 
        background-color: $googleHover !important;
    }
}

.apple {
    color: $white !important;
    background-color: $apple !important;

    &:hover{ 
        background-color: $appleHover !important;
    }
}

.icon {
    margin-right: 5px;
}