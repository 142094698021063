$breakpoint-tablet: 768px;
$gap: 20px;
$halfAGap: 10px;
$backgroundGrey: #282c34;
$black: #000000;
$blackHover: #333333;
$transparentBlack: #00000044;
$lightGray: #999999;
$lightGrayHover: #99999999;
$grey: #434852;
$darkGrey: #3D3D3D;
$darkGreyTransparent: #3D3D3D99;
$deepGrey: #1d2129;
$white: #FFFFFF;
$transparentWhite: #FFFFFF44;
$darkRed: #882235;
$red: #de4c3c;
$transparentRed: #de4c3c44;
$redHover: #e7796d;
$green: #a0de3c;
$greenHover: #bce776;
$orange: #ffc658;
$blue: #00a4eb;
$blueHover: #58aed4;
$darkBlue: #2149b6;
$darkBlueHover: #4f6bb9;
$waze: #00a4eb;
$wazeHover: #58aed4;
$here: #3f454e;
$hereHover: #4e5258;
$google: #1EA362;
$googleHover: #499e75;
$apple: rgb(142,142,147);
$appleHover: rgb(183, 183, 189);

$chart1: #d8849f;
$chart2: #8884d8;
$chart3: #82ca9d;
$chart4: #ffc658;
$chart5: #d0ed57;
$chart6: #8dd1e1;
$chart7: #83a6ed;
$chart8: #a4de6c;

:export {
    breakpointTablet: $breakpoint-tablet;
    darkGrey: $darkGrey;
    lightGray: $lightGray;
    backgroundGrey: $backgroundGrey;
    ownWhite: $white;
    ownBlack: $black;
    chart1: $chart1;
    chart2: $chart2;
    chart3: $chart3;
    chart4: $chart4;
    chart5: $chart5;
    chart6: $chart6;
    chart7: $chart7;
    chart8: $chart8;
    red: $red;
    green: $green;
    orange: $orange;
    blue: $blue;
    darkGreen: $google;
}