@import '../variables.module.scss';

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 40px;

    @media screen and (max-width: $breakpoint-tablet) {
        margin-top: 0;
    }

    .container {
        width: 25% !important;
        min-width: 500px;

        @media screen and (max-width: $breakpoint-tablet) {
            width: 100% !important;
            flex-direction: column !important;
            min-width: auto;
        }
    }

    .label {
        @media screen and (max-width: $breakpoint-tablet) {
            width: 100% !important;
            margin-bottom: 10px;
        }
    }

    .element {
        @media screen and (max-width: $breakpoint-tablet) {
            width: 100% !important;
        }
    }

    .submitContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        @media screen and (max-width: $breakpoint-tablet) {
            width: 100% !important;
        }

        .submitButton {
            width: auto;

            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 1.2em;
                width: 100% !important;
            }
        }
    }
}