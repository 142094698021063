@import '../../variables.module.scss';

.Chart {  
  position: relative;
  font-size: 14px !important;
  width: 100%;
  // display: flex;
  // flex-direction: row;
  margin-bottom: $gap;

  @media screen and (max-width: $breakpoint-tablet) {
    font-size: 12px !important;
    width: 100%;
    padding-top: 0;
    grid-area: row-5;
    // flex-direction: column;
  }

  :global {

    .swiper-button-prev,
    .swiper-button-next {
      @media screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .recharts-default-legend {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  
      .recharts-legend-item {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .recharts-tooltip-wrapper {
      z-index: 102;
      max-width: 100%;
      
      @media screen and (max-width: $breakpoint-tablet) {
        transform: translateY(20%) !important;
      }
  
      &>.recharts-default-tooltip {
        background-color: $grey !important;
  
        &>.recharts-tooltip-label {
          font-weight: 700;
          color: $white !important;
          
          @media screen and (max-width: $breakpoint-tablet) {
            white-space: normal !important;
          }
        }
  
        &>.recharts-tooltip-item-list {
          @media screen and (max-width: $breakpoint-tablet) {
            white-space: normal !important;
          }
  
          &>.recharts-tooltip-item {   
            color: $white !important;

            .recharts-tooltip-item-label,
            .recharts-tooltip-item-value {
              color: $white !important;

              &>span {
                color: $white !important;
              }

              &> svg {
                margin-right: 5px;
              }
            }

            .recharts-tooltip-item-icon {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .svg {
    transform: translateY(-100px);
  }

  .swiper {
    // overflow: visible;
    .slider {
      height: calc(100% - #{$gap});
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .container {
    width: 100%;
    height: 600px;
    display: flex;
    padding-top: 100px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &>div {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: $breakpoint-tablet) {
      height: 500px;
    }

    .radialResponsiveContainer {
      height: calc(200% - 100px) !important;
      width: calc(100% - 50px) !important;
      position: relative;
      z-index: 102;

      @media screen and (max-width: $breakpoint-tablet) {
        height: calc(180% - 100px) !important;
        width: 100%  !important;
      }

      :global {
        .recharts-tooltip-wrapper {
          @media screen and (max-width: $breakpoint-tablet) {
            transform: translateY(200%) !important;
          }
        }
      }
    }

    .pieResponsiveContainer {
      height: calc(100% - 100px);
      width: calc(100% - 50px) !important;
      position: relative;
      z-index: 102;

      @media screen and (max-width: $breakpoint-tablet) {
        width: 100%  !important;
      }

      :global {
        .recharts-tooltip-wrapper {
          @media screen and (max-width: $breakpoint-tablet) {
            transform: translateY(25%) !important;
          }
        }
      }
    }

    .customHeightContainer {
      // z-index: 202;
      width: calc(100% - 50px);
      height: calc(100% - 40px);
      position: relative;
      z-index: 102;

      @media screen and (max-width: $breakpoint-tablet) {
        width: 100%  !important;
      }
    }

    .responsiveContainer {
      // z-index: 202;
      width: calc(100% - 50px) !important;
      height: calc(100% - 40px) !important;
      position: relative;
      z-index: 102;

      @media screen and (max-width: $breakpoint-tablet) {
        width: 100%  !important;
      }
    }

    .calendarBar {
      :global {
        .recharts-default-tooltip {    
          &>.recharts-tooltip-item-list {
            &>.recharts-tooltip-item {
              &:nth-child(1) {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }

  .chartTitle { 
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    text-align: center;
    background-color: $darkGreyTransparent;
    color: $white;
    padding: 5px 20px;
    border-radius: 50px;
    z-index: 105;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
      left: 0;
      right: 0;
      transform: none;
      flex-direction: column;
    }
  }

  .legend {
    display: inline-block;
    margin: auto;
    font-weight: bold;
    font-size: .8em;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-tablet) {
      font-size: .6em;
    }
  }

  .formControl {
    width: auto !important;
    min-width: 100px;
    position: relative !important;
    top: 0 !important; 
    left: 0 !important;
    // margin-top: 0 !important;
    margin-left: 20px;

    @media screen and (max-width: $breakpoint-tablet) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }

    .label {
      font-size: 0.6em;
      transform: translateY(50%) !important;
      pointer-events: none;
      color: $white !important;

      @media screen and (max-width: $breakpoint-tablet) {
        font-size: 1em;
        position: static;
        transform: none !important;
      }
    }

    .select {
      color: $white;
      text-align: left;

      @media screen and (max-width: $breakpoint-tablet) {
        margin-top: 4px;
        margin-left: 16px;
      }
    }
  }
}