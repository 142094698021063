@import '../../variables.module.scss';

.touchfix {
  touch-action: none; 
  user-select: none; 
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

.Chart {
  font-size: 14px !important;
  width: 100%;
  height: 30vh;
  min-height: 200px;
  max-height: 600px;
  
  @media screen and (max-width: $breakpoint-tablet) {
    font-size: 12px !important;
    height: 35vh;
  }

  :global {
    .recharts-tooltip-wrapper {      
      @media screen and (max-width: $breakpoint-tablet) {
        transform: translateY(-100%) !important;
      }
  
      &>.recharts-default-tooltip {
        background-color: $grey !important;
  
        &>.recharts-tooltip-label {
          font-weight: 700;
          color: $white !important;
          
          @media screen and (max-width: $breakpoint-tablet) {
            white-space: normal !important;
          }
        }
  
        &>.recharts-tooltip-item-list {
          @media screen and (max-width: $breakpoint-tablet) {
            white-space: normal !important;
          }
  
          &>.recharts-tooltip-item {   
            color: $white !important;

            .recharts-tooltip-item-label,
            .recharts-tooltip-item-value {
              color: $white !important;

              &>span {
                color: $white !important;
              }

              &> svg {
                margin-right: 5px;
              }
            }

            .recharts-tooltip-item-icon {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .recharts-default-legend {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .recharts-legend-item {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .legend {
    display: inline-block;
    margin: auto;
    font-weight: bold;
    font-size: 1.2em;
    cursor: pointer;
  }
}