@import '../variables.module.scss';

.Users {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        padding-top: 0;
        grid-area: row-6;
    }

    h2 {
        align-self: flex-start;
        margin-top: 0;

        @media screen and (max-width: $breakpoint-tablet) {
            margin-top: 0;
        }

        @media screen and (max-height: $breakpoint-tablet) and (orientation: landscape) {
            margin-top: 0;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
        
        .user {
            width: 100%;
            background: $transparentWhite;
            color: $white;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
            animation: fadein 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
            transition: background-color .2s, opacity .2s;
            overflow: hidden;
            // height: 60px;
            opacity: 0;
            border-left: 15px solid transparent;
            background-color: $deepGrey;
            padding: 20px;

            @media screen and (max-width: $breakpoint-tablet) {
                padding: 0;
                flex-wrap: wrap;
            }

            &:hover { 
                .detail {
                    opacity: .7;
                }
            }

            .detail {
                transition: opacity .2s;
                // margin-bottom: 0;
                position: relative;

                &.clickable {
                    cursor: pointer;
                }
    
                @media screen and (max-width: $breakpoint-tablet) {
                    margin-bottom: 10px;
                    font-size: 0.7em;
                }
                
                >span:first-child {
                }

                >span:nth-child(2) {
                    display: none;
                }

                &:hover {
                    opacity: 1;

                    >span:nth-child(2) {
                        position: absolute;
                        display: block;
                        color: $white;
                        padding: 10px;
                        font-size: 0.6em;
                        width: 200%;
                        white-space: nowrap;
                        top: -2.2em;
                        left: -0.6em;
                    }
                }
            }
        }
    }
}